<!--
 * @Author: your name
 * @Date: 2021-03-30 15:11:31
 * @LastEditTime: 2021-08-20 18:43:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\SunNewComponents\NewTree.vue
-->
<template>
    <div class="item-warp"> 
        <div 
            class="menu-tree-item " 
            v-for="(item,index) in menuList" 
            @click.stop="onClickMenuBox($event,menuList,index)"
            v-bind:key="item.Idx"
            :item-id="`${item.Idx}`"
            :level="level"
            :class="[
                {isChecked:item.Idx == onCheckedItem},
                {'node-line':openGroup.indexOf(item.Idx)!=-1&&item.ChildList.length>0},
                {'leaf-line':item.ChildList.length==0&&level>1},
                {'icon':item.ChildList.length>0&&openGroup.indexOf(item.Idx)==-1},
                {'close-icon':openGroup.indexOf(item.Idx)!=-1&&item.ChildList.length>0},
                {'no-icon':item.ChildList.length==0}
            ]"
        >
            <el-tooltip  popper-class="title-tooltip" 
                :disabled="showTipsArr.indexOf(item.Idx)==-1 || getSessionVal('device') == 'androidPadCKL'" 
                :content="item.Name" placement="right-end">
                <span @touchstart="getTextPositon(item.Idx,$event)" :ref="item.Idx" :id="item.Idx" class="text-box" 
                    :class="{bigUnitWidth:item.IsBigUnit}" :style="{'max-width':level==3?'100%':''}" 
                    :tooltipText="showTipsArr.indexOf(item.Idx)!=-1&&getSessionVal('device') == 'androidPadCKL'?item.Name:''"
                >{{`${item.Name}`}}</span>
            </el-tooltip>
                <!-- <slot v-if="item.IsBigUnit"></slot> -->
                <div class="big-icon-flag" v-if="item.IsBigUnit&&item.ChildList.length==0"></div>
                <div class="big-icon" v-if="item.IsBigUnit&&item.ChildList.length>0"></div>
            <menu-tree 
                v-if="item.ChildList.length>0" 
                :class="{isClosed:openGroup.indexOf(item.Idx)==-1}"
                :menuList="item.ChildList"
                :onCheckedItem="onCheckedItem"
                :level="level+1"
                :openGroup="openGroup"
                :itemId="item.Idx"
                >
                <!-- <slot></slot> -->
            </menu-tree>
        </div>
    </div>
</template>
<script>
import {TreeBus} from './TreeBus'
export default {
    name: 'MenuTree',
    props:['menuList','onCheckedItem','level','openGroup','itemId'],
    components: {},
    data () {
        return {
            showTipsArr:[]
        }
    },
    computed: {
    },
    watch: {
    },
    created () {
    },
    mounted () {
        this.setToolTips()
    },
    methods: {
        onClickMenuBox(e,item,index){
            TreeBus.$emit('onClickMenuItem',{item:item[index],level:this.level})
        },
        //有省略号时显示tips
        setToolTips(){
            for (const key in this.$refs) {
                const element = this.$refs[key];
                let scrollHeight = element[0].scrollWidth
                let clientHeight = element[0].clientWidth
                if(clientHeight<scrollHeight){
                    this.showTipsArr.push(parseInt(element[0].id))
                }
            }
        },
        getTextPositon(id,e){
            console.log(e);
            let root = document.documentElement;
            root.style.setProperty('--getTop',e.touches[0].pageY - 60)
            root.style.setProperty('--getLeft',e.touches[0].pageX + 20)
        }
    },
}
</script>

<style lang='less' scoped>
:root{
    --getTop:10;
    --getLeft:10;
}
.item-warp{
    height: 100%;
    -moz-user-select:none; /*火狐*/
    -webkit-user-select:none; /*webkit浏览器*/
    -ms-user-select:none; /*IE10*/
    -khtml-user-select:none; /*早期浏览器*/
    user-select:none;
}
.menu-tree-item{
    position: relative;
    height: 100%;
    margin-left: 20px;
    padding-top: 10px;
    // padding-left: 10px;
    cursor: pointer;
    // display: flex;
    // align-items: center;
    color: #757575;
    white-space:nowrap;
}
.no-icon{
    padding-left: 30px;
}
.close-icon::before{
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    max-height: 18px;
    background-image: url('/static/images/res/sun-kczx/new-tree-icon.png');
    background-size: 100px 104px;
    background-position: 51px -2px;
    position: relative; 
    left: 0; 
    top: 0px;
    margin-right: 12px;
    z-index: 1;
}
.close-icon::after{
    content: '';
    display: inline-block;
    position: absolute ;
    left: 8px;
    top: -9px;
    width: 6px;
    height: 30%;
    border-left: 1px dashed #DADADA;
    pointer-events: none;
}
.close-icon:last-child::after{
    height: 30px;
}
.icon::before{
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    max-height: 18px;
    background-image: url('/static/images/res/sun-kczx/new-tree-icon.png');
    background-size: 100px 104px;
    background-position: 23px -2px;
    position: relative; 
    left: 0; 
    top: 0px;
    margin-right: 10px;
    z-index: 1;
}
.icon::after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 8px;
    top: -9px;
    width: 6px;
    height: 100%;
    border-left: 1px dashed #DADADA;
    pointer-events: none;
}
.isClosed{
    visibility: hidden;
    height: 0;
}
.isChecked{
    color:#22B9B1;
    font-size: 14px;
}
.node-line:not(:last-child)::after{
    content: '';
    position: absolute;
    height: calc(100%);
    left: 8px;
    top: -6px;
    right: 0px;
    border-left: 1px dashed #DADADA;
    pointer-events: none;
}
.leaf-line::after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 8px;
    top: -38%;
    width: 6px;
    height: 115%;
    border-bottom: 1px dashed #DADADA;
    border-left: 1px dashed #DADADA;
    pointer-events: none;
}
.leaf-line:first-child::after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 6px;
    top: 21%;
    height: 53%;
    border-bottom: 1px dashed #DADADA;
    border-left: 1px dashed #DADADA;
    pointer-events: none;
}
.text-box{
    display: inline-block;
    max-width: calc(90%) !important;
    white-space:nowrap;
    text-overflow:ellipsis; 
    overflow:hidden;
    // wyp 2021/9/16 解决黑框问题
    outline: none;
}
.bigUnitWidth{
    max-width: 70%;
}
.big-icon{
    display: inline-block;
    margin-left: 5px;
    width: 51px;
    height: 22px;
    background-image: url('/static/images/res/sun-kczx/BigUnit.png');
    background-size: 100%;
}
.big-icon-flag{
    display: inline-block;
    margin-left: 5px;
    width: 51px;
    height: 22px;
    background-image: url(/static/images/res/sun-kczx/flag.png);
    background-size: 21%;
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
}
.text-box:after{
    background-color:rgba(74,74,74,0.8);
    border-radius:3px;
    color:#fff;
    content: attr(tooltipText);
    display:none;
    font-size:12px;
    top: calc(var(--getTop) * 1px);
    left: calc(var(--getLeft) * 1px);
    // top: 360px;
    // left: 360px;
    line-height:1.3;
    padding:10px;
    position:fixed;
    text-align:center;
    height: 36px;
    z-index: 99999;
    
}
.text-box:before{
    content:"";
    position:absolute;
    bottom:22px;
    left:50%;
    display:none;
    margin-left:-4px
}
.text-box:active:after,.text-box:active:before{
    display:block;
}
[tooltipText='']:active:after,[tooltipText='']:active:before{
    display:none;
}
.hide-item {
    display: none;
}
</style>
<style>
.title-tooltip{
  	background: rgba(74,74,74,0.80) !important;
    border-radius: 2px !important;
    color: #FFF !important;
    font-size:	12px;
    transform: translate(0,150%);
}
.el-tooltip__popper.is-light{
    border: none !important;
}
.el-tooltip__popper .popper__arrow{
    display: none;
}
</style>