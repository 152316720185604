var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item-warp"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:item.Idx,staticClass:"menu-tree-item ",class:[
            {isChecked:item.Idx == _vm.onCheckedItem},
            {'node-line':_vm.openGroup.indexOf(item.Idx)!=-1&&item.ChildList.length>0},
            {'leaf-line':item.ChildList.length==0&&_vm.level>1},
            {'icon':item.ChildList.length>0&&_vm.openGroup.indexOf(item.Idx)==-1},
            {'close-icon':_vm.openGroup.indexOf(item.Idx)!=-1&&item.ChildList.length>0},
            {'no-icon':item.ChildList.length==0}
        ],attrs:{"item-id":("" + (item.Idx)),"level":_vm.level},on:{"click":function($event){$event.stopPropagation();return _vm.onClickMenuBox($event,_vm.menuList,index)}}},[_c('el-tooltip',{attrs:{"popper-class":"title-tooltip","disabled":_vm.showTipsArr.indexOf(item.Idx)==-1 || _vm.getSessionVal('device') == 'androidPadCKL',"content":item.Name,"placement":"right-end"}},[_c('span',{ref:item.Idx,refInFor:true,staticClass:"text-box",class:{bigUnitWidth:item.IsBigUnit},style:({'max-width':_vm.level==3?'100%':''}),attrs:{"id":item.Idx,"tooltipText":_vm.showTipsArr.indexOf(item.Idx)!=-1&&_vm.getSessionVal('device') == 'androidPadCKL'?item.Name:''},on:{"touchstart":function($event){return _vm.getTextPositon(item.Idx,$event)}}},[_vm._v(_vm._s(("" + (item.Name))))])]),(item.IsBigUnit&&item.ChildList.length==0)?_c('div',{staticClass:"big-icon-flag"}):_vm._e(),(item.IsBigUnit&&item.ChildList.length>0)?_c('div',{staticClass:"big-icon"}):_vm._e(),(item.ChildList.length>0)?_c('menu-tree',{class:{isClosed:_vm.openGroup.indexOf(item.Idx)==-1},attrs:{"menuList":item.ChildList,"onCheckedItem":_vm.onCheckedItem,"level":_vm.level+1,"openGroup":_vm.openGroup,"itemId":item.Idx}}):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }